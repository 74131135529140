<template>
    <section class="configuracion-sistema">
        <div class="row mx-0">
            <div class="menu-left border">
                <div class="row mx-0">
                    <div class="col-12 text-center">
                        <p class="text-86 f-15 f-500 py-4 border-bottom">Configuración del sistema</p>
                    </div>
                </div>
                <!--anuncios-->
                <div class="row mx-0 mt-4">
                    <div class="col-12">
                        <p class="f-17 f-500 mb-2">Anuncios</p>
                    </div>
                    <div class="col-12">
                        <router-link :to="{name:'configurar.tipo.anuncios'}" class="menu-sub-item mb-2 br-8 d-middle pl-3 py-2 justify-content-between pr-1">
                            <p class="f-15">Tipos de anuncios</p>
                            <i class="icon-right-open f-14" />
                        </router-link>
                    </div>
                </div>
                <!--cantabilidad-->
                <div class="row mx-0 mt-4">
                    <div class="col-12">
                        <p class="f-17 f-500 mb-2">Contabilidad</p>
                    </div>
                    <div class="col-12">
                        <router-link :to="{name:'configurar.valor.admin'}" class="menu-sub-item mb-2 br-8 d-middle pl-3 py-2 justify-content-between pr-1">
                            <p class="f-15">Valor de administración</p>
                            <i class="icon-right-open f-14" />
                        </router-link>
                        <router-link :to="{ name:'configurar.fechas.pago' }" class="menu-sub-item mb-2 br-8 d-middle pl-3 py-2 justify-content-between pr-1">
                            <p class="f-15">Fechas de pago</p>
                            <i class="icon-right-open f-14" />
                        </router-link>
                        <router-link :to="{ name:'configurar.usuario.contador' }" class="menu-sub-item mb-2 br-8 d-middle pl-3 py-2 justify-content-between pr-1">
                            <p class="f-15">Usuario contador</p>
                            <i class="icon-right-open f-14" />
                        </router-link>
                    </div>
                </div>
                <!--chat-->
                <div class="row mx-0 mt-4">
                    <div class="col-12">
                        <p class="f-17 f-500 mb-2">Chat</p>
                    </div>
                    <div class="col-12">
                        <router-link :to="{ name: 'configurar.limites.mensajes' }" class="menu-sub-item mb-2 br-8 d-middle pl-3 py-2 justify-content-between pr-1">
                            <p class="f-15">Límites</p>
                            <i class="icon-right-open f-14" />
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-10 col-md col-lg col-xl">
                <router-view class="mt-4" />
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
          
        }
    },
}
</script>

<style lang="scss" scoped>
.configuracion-sistema{
    .menu-left{
        width: 240px;
        height: calc(100vh - 55px);
        background: #FAFBFC 0% 0% no-repeat padding-box;
        box-shadow: 1px 0px 2px #00000029;
        .menu-sub-item{
            &:hover{
                @extend .bg-active;
                cursor: pointer;
            }
        }
        .bg-active{
            background: #EBECF0;
            i.icon-right-open{
                opacity: 1;
            }
        }
        i.icon-right-open{
            opacity: 0.5;
        }
    }
    a{
        color: inherit;
        &:hover{
            background: #EBECF0;
        }
        &.router-link-exact-active.router-link-active{
            background: #EBECF0;
            
        }
        &:hover{
            text-decoration: none !important;
        }
    }
}
</style>
